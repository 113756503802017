export default [
  {
    key: "lead_name",
    label: "Name",
    sortable: true,
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
  {
    key: "mobile",
    label: "Mobile",
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
  {
    key: "account",
    label: "Account",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    visible: true,
  },
  {
    key: "charges",
    label: "Fee & Charges",
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
  {
    key: "monthly_amount",
    label: "MP",
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
  {
    key: "last_payment",
    label: "LP",
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
  {
    key: "status_payment",
    label: "PT",
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
  {
    key: "state_advisor",
    label: "Advisor",
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
  {
    key: "created_at",
    label: "Creation Date",
    visible: true,
    thClass: "text-center",
    tdClass: "",
  },
];
