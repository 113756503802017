<template>
  <div class="px-2 mt-1">
    <header-slot :clientsSearch="true" :global-search="true">
      <template v-if="changeNavAction == true" #actions>
        <b-row>
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              class="mr-2 mb-1"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <!-- Title Search Global -->
          <!-- Input Search Global -->
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info">GLOBAL SEARCH</b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                @keyup.enter="search"
                placeholder="Search by Name, Account or the last 4 digits of Phone number"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'clients-main' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Clients
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'clients-shared' }"
        exact
        :active="activeShared"
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Shared
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view />
    </b-card>

    <modal-global-search
      v-if="modalGlobalSearch"
      :program-id="programId"
      :role-id="currentUser.role_id"
      :search-text="searchText"
      @closeModal="closeGlobalSearch"
    />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import { mapGetters } from "vuex";
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";

export default {
  components: {
    ModalGlobalSearch,
    ModalSearchPayment,
  },
  data() {
    return {
      openModalSearchPayment: false,
      changeNavAction: true,
      searchText: "",
      modalGlobalSearch: false,
      firstTime: true,
      moduleActual: null,
      permitStatus: null,
      isOnlyLead: false,
      programId: 3,
    };
  },
  computed: {
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    routeName() {
      return this.$route.matched[1]?.name;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    activeShared() {
      return (
        this.$route.name === "shared-of-program" ||
        this.$route.name === "shared-other-program"
      );
    },
  },
  mounted() {
    this.getAccessModules();
  },
  created() {
    this.redirectByUser();
    this.moduleId();
  },
  methods: {
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
    redirectByUser() {
      if (this.firstTime && [8, 3].includes(this.currentUser.role_id)) {
        return this.$router.push({ name: "shared-other-program" });
      }
    },
  },
};
</script>
