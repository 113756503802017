<template>
  <div>
    <b-modal
      v-model="modalSearch"
      title="GLOBAL SEARCH"
      title-class="h3 text-white font-weight-bolder"
      size="xl"
      hide-footer
      no-close-on-backdrop
      scrollable
      @hidden="closeModal"
    >
      <!-- table -->
      <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refGlobalSearch'].refresh()"
        @onChangeCurrentPage="onChangeCurrentPage"
      >
        <b-table
          ref="refGlobalSearch"
          slot="table"
          primary-key="id"
          :items="searchClientsProvider"
          :fields="arrayColumns"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          no-provider-filtering
          table-class="text-nowrap text-right"
          responsive="sm"
          show-empty
          sticky-header="50vh"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #head(lead_name)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>
          <template #head(account)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(status)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <!-- Column NAME -->
          <template #cell(lead_name)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              {{ data.item.lead_name }}
            </div>
          </template>
          <!-- Column Mobile -->
          <template #cell(mobile)="data">
            <div class="d-flex justify-content-center align-items-start">
              <span>{{ data.item.mobile }}</span>
            </div>
          </template>
          <!-- Column Account -->
          <template #cell(account)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none; text-align: center"
                >
                  <span>
                    <router-link
                      v-if="
                        accessAll ||
                        account.module_id === moduleId ||
                        account.module_id === childModuleId ||
                        (account.module_id == 6 && moduleId == 23)
                      "
                      target="_blank"
                      :to="clientDashboardRouteName(account)"
                    >
                      {{ account.account }}
                    </router-link>
                    <span v-else>
                      {{ account.account }}
                    </span>
                    <span
                      v-if="account.program_id === 3"
                      :class="{
                        'text-primary': account.client_type_id === 2,
                        'text-success': account.client_type_id !== 2,
                      }"
                    >
                      {{ account.client_type_id === 2 ? "Ⓓ" : "Ⓡ" }}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column STATUS -->
          <template #cell(status)="data">
            <div class="d-flex justify-content-start">
              <ul style="margin-bottom: 0px" class="pl-4">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none"
                >
                  <div
                    class="d-flex flex-column justify-content-start align-items-start"
                  >
                    <status-account :account="account" />
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column FEE & CHARGES -->
          <template #cell(charges)="data">
            <div class="d-flex justify-content-center">
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none"
                >
                  <div
                    class="d-flex flex-column justify-content-start align-items-start"
                  >
                    $ {{ account.charges || 0 }}
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column MP -->
          <template #cell(monthly_amount)="data">
            <div class="d-flex justify-content-center">
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none"
                >
                  <div
                    class="d-flex flex-column justify-content-start align-items-start"
                  >
                    $ {{ account.monthly_amount }}
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column LP -->
          <template #cell(last_payment)="data">
            <div class="d-flex justify-content-center">
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none"
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-start"
                  >
                    <span v-if="account.last_payment != '-'">
                      <b-img
                        v-if="account.state_lp == 1"
                        :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-out.ico`"
                        style="top: 50%; transform: translate(0, -20%)"
                      />
                      <b-img
                        v-else
                        :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-in.ico`"
                        style="top: 50%; transform: translate(0, -20%)"
                      />
                      {{
                        account.last_payment == "-" ? "" : account.last_payment
                      }}
                      <feather-icon
                        icon="CreditCardIcon"
                        class="mp-pending"
                        v-if="account.pending_payments == 1"
                        style="top: 50%"
                        v-b-tooltip.hover
                        title="1 Monthly Payment Pending"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column PT -->
          <template #cell(status_payment)="data">
            <div class="d-flex justify-content-center">
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none"
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-start"
                  >
                    <status-payment :account="account" />
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column ADVISOR -->
          <template #cell(state_advisor)="data">
            <div class="d-flex justify-content-center">
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <feather-icon
                      v-if="account.advisor_id"
                      id="icon"
                      icon="CircleIcon"
                      size="13"
                      class="green"
                    />
                    {{ account.advisor_name }}
                    <FeatherIcon
                      v-if="
                        account.advisor_id &&
                        (account.mobile_advisor || account.advisor_extension)
                      "
                      :id="account.id"
                      v-b-tooltip.hover.right="
                        account.show_advisor_data ? 'show less' : 'show more'
                      "
                      class="cursor-pointer"
                      :class="
                        account.show_advisor_data
                          ? 'text-danger'
                          : 'text-primary'
                      "
                      :icon="
                        account.show_advisor_data
                          ? 'ChevronsUpIcon'
                          : 'ChevronsDownIcon'
                      "
                      @click="
                        account.show_advisor_data = !account.show_advisor_data
                      "
                    />
                    <!-- <pre>{{account}}</pre> -->
                  </div>
                  <transition name="bounce">
                    <div
                      v-if="account.show_advisor_data"
                      class="pl-2 d-flex flex-column justify-content-start align-items-start"
                    >
                      <span v-if="account.mobile_advisor != null">
                        {{ "Phone: " + account.mobile_advisor }}
                      </span>
                      <span v-if="account.advisor_extension != null">
                        {{ "Ext: " + account.advisor_extension }}
                      </span>
                    </div>
                  </transition>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column CREATION DATE -->
          <template #cell(created_at)="data">
            <div class="text-center">
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  style="list-style: none"
                >
                  <span>
                    {{ account.created_at | myGlobalWithHour }}
                  </span>
                </li>
              </ul>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-modal>
  </div>
</template>

<script>
// STORE
import { mapGetters } from "vuex";
// Import Data
import ClientService from "@/views/commons/components/clients/services/clients.services";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import fields from "../data/fields.global.search.data";
// Import Components
import StatusAccount from "../stylescomponents/StatusAccount.vue";
import StatusPayment from "../stylescomponents/StatusPayment.vue";

export default {
  components: {
    StatusAccount,
    StatusPayment,
    FilterSlot,
  },
  props: {
    programId: {
      type: Number,
      required: true,
    },
    searchText: {
      type: String,
      default: "",
    },
    roleId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    childModuleId() {
      return this.$route.matched[0].meta.childModule;
    },
    accessAll() {
      return (
        this.isCeo ||
        [4, 16, 18, 25].includes(this.moduleId) ||
        this.isSpecialist
      );
    },
    isSpecialist() {
      return [11, 28, 29, 30].includes(this.moduleId);
    },
  },
  async created() {
    this.modalSearch = true;
  },
  async mounted() {},
  data() {
    return {
      modalSearch: false,
      data: [],
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      arrayColumns: fields,
      isBusy: false,
      allClients: [],
      providerItems: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      globalSearchText: this.searchText,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: null,
      nextPage: "",
      toPage: null,
    };
  },
  methods: {
    closeModal() {
      this.modalSearch = false;
      this.$emit("closeModal");
    },
    async setAllClients() {
      if (this.providerItems.length < 1) return;
      this.providerItems.forEach((element) => {
        const accounts = JSON.parse(element.accounts);
        // eslint-disable-next-line no-shadow
        accounts.forEach((element) => {
          element.show_advisor_data = false;
        });
        element.accounts = accounts;
      });
    },
    clientDashboardRouteName(account) {
      let supervisor = {
        name: this.clientDashboardRoutes[this.moduleId],
        params: {
          idClient: `${account.account_id}`,
        },
      };
      let ceo = this.routerDashboardRedirectioner(
        account.program_id,
        account.account_id
      );
      console.log(ceo);
      return this.isCeo ? ceo : supervisor;
    },
    // eslint-disable-next-line consistent-return
    async searchClientsProvider() {
      if (this.globalSearchText) {
        this.filterPrincipal.model = this.globalSearchText;
        this.globalSearchText = "";
      }
      try {
        this.addPreloader();
        const params = {
          text: this.filterPrincipal.model,
          from: null,
          to: null,
          program: this.programId,
          orderby: 5,
          order: "desc",
          status: null,
          advisor: null,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
          type: null,
          day: null,
          rol_id: 1, //this.roleId,
          showall: true,
        };
        this.isBusy = true;
        const data = await ClientService.search(params);
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        this.providerItems = data.data.data;
        await this.setAllClients();
        this.isBusy = false;
        this.removePreloader();
        return this.providerItems || [];
      } catch (error) {
        this.isBusy = false;
        console.error(error);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
  },
};
</script>

<style scoped lang="scss">
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.green {
  color: #00cc00;
  border-color: #00cc00;
  background: #00cc00;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: all 0.25s ease-in-out;
}

.bounce-enter,
.bounce-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.bounce-enter-to,
.bounce-leave {
  opacity: 1;
  transform: translateY(0);
}

.bounce-leave-to {
  transform: translateY(50px);
}

.mp-pending {
  animation: growup 1.5s ease-in-out infinite;
  top: calc(50% - 10px);
}
@keyframes growup {
  0% {
    transform: scale(1);
    color: #ff9f43;
  }
  50% {
    transform: scale(0.99);
    color: #fc3a31;
  }
  100% {
    transform: scale(1);
    color: #ff9f43;
  }
}
</style>
